import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Link, Element } from 'react-scroll'
import SEO from '../components/SEO'

import Layout from '../components/Layout'

class FaqsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeFaq: 0,
    }
  }

  componentDidMount = () => {
    if (window.location.hash) {
      const hash = parseInt(window.location.hash.substr(1)) - 1

      this.setState({ activeFaq: hash })
    }
  }

  render() {
    const { data, location } = this.props
    const page = data.prismicFaqs.data

    return (
      <Layout>
        <SEO
          title={page.meta_title}
          description={page.meta_description}
          location={location}
        />
        <div className="pt-32 lg:pt-36 mb-8">
          <div className="grid">
            <div className="grid__half--primary">
              <div>
                <h1 className="type-lg lg:type-3xl text-white">{page.title}</h1>
              </div>
            </div>
          </div>
        </div>

        <Element name="vote" className="hidden lg:block" />
        <div className="faq-grid mb-16 lg:mb-24 pt-8">
          <div className="faq-grid__menu">
            <div className="w-full">
              {page.faqs.map((item, i) => {
                return (
                  <Link
                    key={i}
                    smooth={true}
                    duration={500}
                    to="vote"
                    onClick={() => {
                      this.setState({ activeFaq: i })
                    }}
                    className="faq-menu-item"
                  >
                    <div
                      className={`faq-menu-item__head lg:w-4/5 text-xl mb-4 link ${
                        this.state.activeFaq === i ? 'text-white' : ''
                      }`}
                    >
                      <div className="faq-menu-item__index">{i + 1}.</div>
                      <div className="faq-menu-item__title">
                        {item.faq_title}
                      </div>
                    </div>
                    <div
                      className={`lg:force-hidden rte mb-10 ${
                        this.state.activeFaq === i ? 'block' : 'hidden'
                      }`}
                      dangerouslySetInnerHTML={{ __html: item.faq_body.html }}
                    />
                  </Link>
                )
              })}
            </div>
          </div>
          <div className="faq-grid__divider border-l border-white hidden lg:block" />
          <div className="faq-grid__main hidden lg:block">
            <div>
              {page.faqs.map((item, i) => {
                return (
                  <div
                    key={i}
                    className={this.state.activeFaq === i ? 'block' : 'hidden'}
                  >
                    <div className="text-xl mb-4">{item.faq_title}</div>
                    <div
                      className="faq-body rte"
                      dangerouslySetInnerHTML={{ __html: item.faq_body.html }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

FaqsPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageFaqsQuery = graphql`
  query {
    prismicFaqs {
      data {
        title
        faqs {
          faq_title
          faq_body {
            html
            text
          }
        }
        meta_title
        meta_description
      }
    }
  }
`

export default FaqsPage
